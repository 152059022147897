import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authStore } from '@/stores/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/qr-codes',
    name: 'QRCodes',
    component: () => import(/* webpackChunkName: "qrCodes" */ '../views/qr/QRCodes.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/qr-code/toevoegen',
    name: 'QRCodeCreate',
    component: () => import(/* webpackChunkName: "qrCodeCreate" */ '../views/qr/QRCode.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/qr-code/:qrId',
    name: 'QRCodeShow',
    component: () => import(/* webpackChunkName: "qrCodeShow" */ '../views/qr/QRCode.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/qr-code/:qrId/bewerken',
    name: 'QRCodeEdit',
    component: () => import(/* webpackChunkName: "qrCodeShow" */ '../views/qr/QRCode.vue'),
    meta: {
      authRequired: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.authRequired) && !authStore().loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
