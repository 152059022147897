<template>
  <div class="header-left" @click="redirectToDashboard">
    <img src="/img/logo/Logo.png" height="30" alt="Logo van QR365" />
  </div>
  <div class="header-right">
    <menu-outlined :style="{ fontSize: '18px' }" @click="toggleMobileNav" />
    <a-drawer title="Navigatie" placement="left" :body-style="{ padding: '0' }" v-model:visible="showMobileNav">
      <sidebar @close-nav="toggleMobileNav" />
    </a-drawer>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { MenuOutlined } from '@ant-design/icons-vue'
import Sidebar from '@/components/base/Sidebar.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const showMobileNav = ref(false)

function toggleMobileNav () : void {
  showMobileNav.value = !showMobileNav.value
}

function redirectToDashboard () : void {
  router.push({ name: 'Dashboard' })
}
</script>

<style lang="less" scoped>
.header-left {
  display: flex;
  height: 64px;
  padding: 17px 24px;
  cursor: pointer;

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 0;

    span {
      color: #f38929;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0;
  }
}
</style>
