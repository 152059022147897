<template>
  <div class="header-left"></div>
  <div class="header-right">
    <UserOutlined :style="{ fontSize: '18px' }" @click="router.push({ name: 'Account' })" />
    <LogoutOutlined :style="{ fontSize: '18px', marginLeft: '16px' }" @click="logout" />
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/stores/auth'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'

const auth = authStore()
const router = useRouter()

function logout () : void {
  auth.logout()
}
</script>

<style lang="less" scoped></style>
