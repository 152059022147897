import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import router from '@/router'
import { notification } from 'ant-design-vue'

const ls = new SecureLS({ isCompression: false })

export const authStore = defineStore('auth', () => {
  const loading = ref(false)
  const token = ref(ls.get('token') || null)
  const user = ref(ls.get('user') || null)
  const loggedIn = computed(() => !!token.value)

  function login (email:string|null, password:string|null) {
    loading.value = true
    axios.post(process.env.VUE_APP_API_URL + '/v1/login', {
      email: email,
      password: password
    }).then((r) => {
      // Set authenticated user.
      user.value = r.data.user
      ls.set('user', user.value)

      // Set token.
      token.value = 'Bearer ' + r.data.access_token
      ls.set('token', token.value)

      // Set bearer token for axios calls.
      axios.defaults.headers.common.Authorization = token.value

      router.push({ name: 'Dashboard' })
    }).finally(() => {
      loading.value = false
    })
  }

  function logout (forcedLogout = false) {
    axios.post(process.env.VUE_APP_API_URL + '/v1/logout')

    // Clear token and localstorage.
    token.value = null
    ls.clear()

    // Remove bearer token for axios calls.
    delete axios.defaults.headers.common.Authorization

    if (forcedLogout) {
      router.push('/').then(() => {
        user.value = null
        notification.info({
          message: 'Sessie verlopen!',
          description: 'Uw sessie is verlopen en u bent automatisch uitgelogd. Om verder te gaan kunt u opnieuw inloggen.',
          duration: 5
        })
      })
    } else {
      router.push('/').then(() => {
        user.value = null
        notification.success({
          message: 'Succesvol uitgelogd.',
          description: 'Uw bent succesvol uitgelogd. U kunt dit scherm nu sluiten.',
          duration: 5
        })
      })
    }
  }

  return { loading, user, loggedIn, login, logout }
})
