<template>
  <a-config-provider :locale="locale">
    <div :class="mainTemplate ? 'main-grid' : 'main-grid-guests'">
      <div class="header-area header-grid" v-if="mainTemplate">
        <header-bar />
      </div>
      <div class="header-area-mobile header-grid" v-if="mainTemplate">
        <mobile-header-bar />
      </div>
      <div class="sidebar-area" v-if="mainTemplate">
        <sidebar />
      </div>
      <div :class="mainTemplate ? 'content-area' : 'content-area-guests'">
        <router-view />
      </div>
      <div class="footer-area footer-grid" v-if="mainTemplate">
        <div class="footer-left-area">
          <span>&copy; {{new Date().getFullYear()}} - QR365.nl</span>
        </div>
        <div class="footer-right-area">
          <span>v{{appVersion}}</span>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import nlNL from 'ant-design-vue/es/locale/nl_NL'
import Sidebar from '@/components/base/Sidebar.vue'
import { authStore } from '@/stores/auth'
import HeaderBar from '@/components/base/HeaderBar.vue'
import MobileHeaderBar from '@/components/base/MobileHeaderBar.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'

dayjs.locale('nl')

const locale = nlNL
const auth = authStore()
const route = useRoute()
const appVersion = process.env.VUE_APP_VERSION
const mainTemplate = computed(() => {
  return auth.loggedIn && typeof route.name === 'string' && !['Login'].includes(route.name)
})
</script>

<style lang="less">
.main-grid {
  height: 100vh;
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: 64px 1fr 64px;
  grid-template-areas:
    "sidebar header header header"
    "sidebar content content content"
    "sidebar footer footer footer";
}

.main-grid-guests {
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "content";
}

@media screen and (max-width: 1024px) {
  .main-grid {
    height: 100vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 64px 1fr 64px;
    grid-template-areas:
    "header"
    "content"
    "footer";
  }
}

.header-area {
  grid-area: header;
  background: white;
}

@media screen and (max-width: 1024px) {
  .header-area {
    display: none!important;
  }
}

.header-area-mobile {
  grid-area: header;
  background: white;
}

@media screen and (min-width: 1025px) {
  .header-area-mobile {
    display: none!important;
  }
}

.header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "header-left header-right";

  .header-left {
    padding: 17px 16px;
    align-self: center;
  }

  .header-right {
    padding: 0 16px;
    align-self: center;
    text-align: right;
  }
}

.sidebar-area {
  grid-area: sidebar;
  background: white;
}

@media screen and (max-width: 1024px) {
  .sidebar-area {
    display: none;
  }
}

.content-area {
  grid-area: content;
  overflow-y: scroll;
  padding: 16px;
  background: #f0f2f5;
}

.content-area-guests {
  grid-area: content;
  overflow-y: scroll;
  padding: 0;
  background: white;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "footer-left footer-left footer-right";
}

.footer-area {
  grid-area: footer;
  background: white;
  padding: 0 16px;
  border-top: 1px solid #f0f2f5;
}

.footer-left-area {
  grid-area: footer-left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-right-area {
  grid-area: footer-right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
