<template>
  <div class="sidebar">
    <div class="logo" @click="redirectToDashboard">
      <img src="/img/logo/Logo.png" height="30" alt="Logo van QR365" />
    </div>
    <a-menu class="sidebar-navigation" mode="inline" v-model:selectedKeys="selectedKeys" @click="navigateTo">
      <a-menu-item key="Dashboard">
        <LayoutOutlined />
        <span>Dashboard</span>
      </a-menu-item>
      <a-menu-item key="QRCodes">
        <QrcodeOutlined />
        <span>QR codes</span>
      </a-menu-item>
      <a-menu-item key="Logout" @click.stop="authStore().logout()">
        <LogoutOutlined />
        <span>Uitloggen</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { LayoutOutlined, QrcodeOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { authStore } from '@/stores/auth'

const selectedKeys = ref<string[]>([])
const route = useRoute()
const router = useRouter()
const emit = defineEmits(['closeNav'])

watch(() => route.name, () => {
  setActiveRoute()
})

onMounted(() => {
  setActiveRoute()
})

function setActiveRoute () : void {
  if (typeof route.name === 'string') {
    switch (route.name) {
      case 'QRCodes':
      case 'QRCodeCreate':
      case 'QRCodeEdit':
      case 'QRCodeShow':
        selectedKeys.value = ['QRCodes']
        break
      default:
        selectedKeys.value = [route.name]
        break
    }
  }
}

function navigateTo (navItem:{ key:string }) : void {
  if (route.name !== navItem.key && navItem.key !== 'Logout') {
    const keyboard = window.event as KeyboardEvent
    if (keyboard.ctrlKey) {
      const route = router.resolve({ name: navItem.key })
      window.open(route.href, '_blank')
    } else {
      router.push({ name: navItem.key })
    }
    emit('closeNav')
  }
}

function redirectToDashboard () : void {
  router.push({ name: 'Dashboard' })
}
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  height: 64px;
  padding: 17px 24px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .logo {
    display: none;
  }
}
</style>
